<template>
  <div class="payment-methods" v-if="spendingCardTypeList?.length > 0">
    <!-- <h2 class="payment-panels-title">Altri Pagamenti</h2> -->
    <v-expansion-panels
      flat
      accordion
      v-model="selectedPayment"
      :disabled="isPaymentDisabled || giftCardSelectorLocked"
      :value="selectedPayment"
    >
      <v-expansion-panel
        v-for="spendingCardType in spendingCardTypeList"
        v-bind:key="spendingCardType.paymentTypeId"
        @click="selectSpendingCardType(spendingCardType)"
      >
        <v-expansion-panel-header hide-actions v-on:click.prevent="() => {}">
          <!-- contenitore generale degli elementi della label -->
          <label
            :for="'test' + spendingCardType.paymentTypeId"
            class="d-flex flex-row"
          >
            <!-- nome del tipo di pagamento -->
            <div
              class="d-flex flex-column gift-card-heading-wrapper align-center"
            >
              <span class="text-uppercase">
                {{ spendingCardType.paymentType.paymentName }}
              </span>
            </div>
          </label>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div :class="{ 'payment-disabled': isPaymentDisabled }">
            <GatewayWriter
              :payment-type="spendingCardType.paymentType"
              :order-id="orderId"
              :component-name="spendingCardType.paymentType.gatewayClient"
              :cartInfos="cartInfos"
              :mode="mode"
              :acceptConditions="acceptConditions"
              :selected="spendingCardType.selected"
            />
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<style scoped lang="scss">
.gift-card-heading-wrapper {
  display: flex;
  width: 100%;
  flex-direction: row !important;
  .logo-heading-image {
    width: 120px;
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      width: 100px;
    }
  }
  .text-uppercase {
    flex-grow: 1;
  }
}
</style>
<script>
import GatewayWriter from "~/components/payment/gateways/GatewayWriter.vue";
import SpendingCardService from "~/service/spendingCard/spendingCardService";
import { mapActions } from "vuex";

export default {
  name: "SpendingCardTypeList",

  props: {
    orderId: { type: Number, required: false },
    mode: { type: String, required: true },
    cartInfos: { type: Object },
    isPaymentDisabled: { type: Boolean, default: false },
    acceptConditions: { type: Boolean, default: false }
  },
  components: { GatewayWriter },
  data() {
    return {
      selectedPayment: null,
      giftCardSelectorLocked: false,
      spendingCardTypeList: []
    };
  },
  methods: {
    ...mapActions({
      getCart: "cart/getCart"
    }),
    async fetchSpendingCardTypeList() {
      this.spendingCardTypeList = await SpendingCardService.list();
      console.log(this.spendingCardTypeList);
      this.giftCardSelectorLocked = false;
      for (var i = 0; i < this.spendingCardTypeList?.length; i++) {
        if (this.spendingCardTypeList[i].giftCertificateConfirmed == true) {
          this.giftCardSelectorLocked = true;
        }

        if (
          this.spendingCardTypeList[i].logged == true &&
          this.acceptConditions == false
        ) {
          this.$emit("toggleAcceptConditions");
        }
        if (this.spendingCardTypeList[i].selected) {
          this.selectedPayment = i;
          break;
        }
      }
    },

    async selectSpendingCardType(spendingCardType) {
      let spendingCardTypes = await SpendingCardService.select(
        spendingCardType.paymentType.paymentTypeId,
        !spendingCardType.selected
      );
      this.spendingCardTypeList = spendingCardTypes;
      this.getCart();
    }
  },
  mounted() {
    this.fetchSpendingCardTypeList();
  },
  watch: {
    isPaymentDisabled(isDisabled) {
      if (isDisabled) this.selectedPayment = null;
    }
  }
};
</script>
@/commons/service/spendingCard/spendingCardService
